import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import PreAuthTopBar from 'components/global/PreAuthTopBar';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment-timezone';
import TextField from 'material-ui/TextField';
import PasswordField from 'material-ui-password-field';
import { fetchUser, updateUser } from 'actions/user';
import { setAppLocale } from 'actions/misc';
import SvgIcon from 'material-ui/SvgIcon';
import LocaleSelectMini from 'components/global/LocaleSelectMini';
import { COUNTRY, OTHER_COUNTRY_URL } from '../../environment';
import { signinUser, signinZendeskUser } from '../../actions/auths';
import isAdmin from '../../utils/isAdmin';
import Spinner from '../../../images/global/spinner.svg';
import { setDefaultTimezone } from '../../utils/timezone';

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      pending: false,
    };
    this.isZendesk = _.get(this.props.location, 'search').includes('return_to');
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    if (this.props.authenticated) {
      if (this.isZendesk) {
        this.props.history.push('/zendesk_redirect', {
          prevUrl: this.props.location.search,
        });
      } else {
        this.props.history.push('/dashboard');
      }
    }
  }

  updateLocale = user => {
    const locale = localStorage.getItem('locale');
    if (locale !== user.locale) {
      this.props.updateUser({ locale }, () => window.location.reload());
    }
  };

  onSubmit(values) {
    const formValues = { ...values, source: 'practice' };
    this.setState({ pending: true });

    this.props.signinUser(
      formValues,
      () => {
        if (this.props.two_factor) {
          this.props.history.push('/two_factor');
        } else if (this.isZendesk) {
          this.props.history.push('/zendesk_redirect', {
            prevUrl: this.props.location.search,
          });
        } else {
          this.props.fetchUser(res => {
            const user = res.data;
            if (isAdmin(user.roles)) {
              this.props.history.push('/admin');
            } else {
              this.props.history.push('/dashboard');
            }
            moment.locale(user.locale);
            setDefaultTimezone(user);
            this.updateLocale(user);
          });
        }
      },
      () => {
        this.setState({ pending: false });
      },
    );
  }

  renderField = field => {
    const {
      meta: { touched, error },
    } = field;
    return (
      <div>
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...field.input}
          value={field.value}
          type={field.type}
          errorText={touched ? error : ''}
          floatingLabelFixed
          floatingLabelText={field.label}
        />
      </div>
    );
  };

  renderPasswordField = field => (
    <div className="input-row">
      <PasswordField
        floatingLabelFixed
        floatingLabelText={field.label}
        errorText={field.meta.dirty && field.meta.error}
        style={{ width: 256 }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...field.input}
      />
    </div>
  );

  renderCountrySwitch = () => {
    const canadian = (
      <FormattedMessage
        defaultMessage="You are on the Canadian version of the GreenShield Health Practitioner Portal. If you are an American Practitioner, <a>visit the American version of the Practitioner Portal.</a>"
        values={{
          a: address => (
            <a href={OTHER_COUNTRY_URL} className="link">
              {address}
            </a>
          ),
        }}
      />
    );

    const american = (
      <FormattedMessage
        defaultMessage="You are on the American version of the GreenShield Health Practitioner Portal. If you are a Canadian Practitioner, <a>visit the Canadian version of the Practitioner Portal.</a>"
        values={{
          a: address => (
            <a href={OTHER_COUNTRY_URL} className="link">
              {address}
            </a>
          ),
        }}
      />
    );

    return <p>{COUNTRY === 'CA' ? canadian : american}</p>;
  };

  renderAlert() {
    if (this.props.errorMessage) {
      return <div className="server-error">{this.props.errorMessage}</div>;
    }
    return <div />;
  }

  renderSignInButton = () => {
    const { pristine, submitting } = this.props;
    const { pending } = this.state;

    const label = pending ? (
      <span className="wrapper">
        <span className="spinner">
          <SvgIcon color="#ffffff">
            <Spinner />
          </SvgIcon>
        </span>
        <span className="label">
          <FormattedMessage defaultMessage="SIGN IN" />
        </span>
      </span>
    ) : (
      <FormattedMessage defaultMessage="SIGN IN" />
    );

    return (
      <button
        className={`signin-button ${pending ? 'spinner-button' : ''}`}
        type="submit"
        disabled={pristine || submitting || pending}
      >
        {label}
      </button>
    );
  };

  render() {
    const { handleSubmit, locale } = this.props;

    return (
      <>
        <PreAuthTopBar locale={locale} />
        <div className="signin-container">
          <div className="row">
            <div className="col" style={{ minWidth: 315 }}>
              <div className="auth-form center-items">
                <h4>
                  <FormattedMessage defaultMessage="Sign In" />
                </h4>
                {this.renderCountrySwitch()}
                <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                  <Field
                    label={<FormattedMessage defaultMessage="email" />}
                    name="email"
                    value={this.state.email}
                    component={this.renderField}
                  />
                  <Field
                    label={<FormattedMessage defaultMessage="password" />}
                    name="password"
                    type="password"
                    value={this.state.password}
                    component={this.renderPasswordField}
                  />
                  <br />
                  <div className="row center-items">
                    {this.renderAlert()}
                    {this.renderSignInButton()}
                  </div>
                  <br />
                  <div className="row center-items">
                    <Link to="/forgot_password">
                      <p>
                        <strong>
                          <FormattedMessage defaultMessage="Forgot my password" />
                        </strong>
                      </p>
                    </Link>
                  </div>
                  <LocaleSelectMini />
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

SignIn.propTypes = {
  authenticated: PropTypes.bool,
  two_factor: PropTypes.bool,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  signinUser: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  signinZendeskUser: PropTypes.func.isRequired,
  fetchUser: PropTypes.func.isRequired,
  setAppLocale: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  locale: PropTypes.string.isRequired,
};

SignIn.defaultProps = {
  authenticated: false,
  two_factor: false,
  errorMessage: '',
};

function validate(values) {
  const errors = {};

  if (
    !values.email ||
    !/^[À-ÿA-Z0-9._%+-]+@[À-ÿA-Z0-9.-]+\.[A-Z]{2,18}$/i.test(values.email)
  ) {
    errors.email = (
      <FormattedMessage defaultMessage="Please enter a valid email" />
    );
  }

  if (!values.password) {
    errors.password = <FormattedMessage defaultMessage="Enter password!" />;
  }
  return errors;
}

function mapStateToProps(state) {
  return {
    locale: state.misc.appLocale,
    authenticated: state.auth.authenticated,
    two_factor: state.auth.two_factor,
    errorMessage: state.auth.error,
  };
}

export default reduxForm({
  validate,
  form: 'SigninForm',
})(
  connect(mapStateToProps, {
    signinUser,
    signinZendeskUser,
    fetchUser,
    updateUser,
    setAppLocale,
  })(SignIn),
);
